import { Suspense, cloneElement, useMemo } from "react";
import { graphql } from "relay-runtime";
import { useFragment, useRefetchableFragment } from "react-relay";
import ActivityCalendar, {
  ThemeInput,
  Activity,
  Skeleton,
} from "react-activity-calendar";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "./Button";
import {
  UserActivityCalendarFragment$key,
  UserActivityCalendarFragment$data,
} from "./__generated__/UserActivityCalendarFragment.graphql";
import { UserActivityFragment$key } from "./__generated__/UserActivityFragment.graphql";
import {
  ACTIVITY_KINDS,
  ActivityFilter,
  formatActivityKind,
  useSetSearchActivityFilters,
} from "../utils/activityTracker";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { EntityActivitiesConnectionKind } from "./__generated__/UserActivityCalendarFragmentQuery.graphql";
import { addIfDefined } from "../utils/helpers";

const MILLISECONDS_IN_A_DAY = 86_400_000;
const GAP_BETWEEN_DATES = 2;
const REACT_TOOLTIP_ID = "react-tooltip-id";

const UserActivityFragment = graphql`
  fragment UserActivityFragment on User
  @argumentDefinitions(
    kinds: { type: "[EntityActivitiesConnectionKind!]" }
    date: { type: "String!" }
  ) {
    createdAt
    ...UserActivityCalendarFragment @arguments(kinds: $kinds, date: $date)
  }
`;

const UserActivityCalendarFragment = graphql`
  fragment UserActivityCalendarFragment on User
  @refetchable(queryName: "UserActivityCalendarFragmentQuery")
  @argumentDefinitions(
    kinds: { type: "[EntityActivitiesConnectionKind!]" }
    date: { type: "String!" }
    first: { type: "Int", defaultValue: 366 }
  ) {
    activities(first: $first, after: $date, kinds: $kinds) {
      edges {
        node {
          date
          level
          points
        }
      }
    }
  }
`;

const explicitTheme: ThemeInput = {
  light: ["#eceafc", "#a99cf3", "#6953ea", "#3d24d0", "#25167e"],
  dark: ["#000000", "#101828", "#4328E5", "#182230", "#25167E"],
};

interface UserActivityProps {
  user: UserActivityFragment$key;
  date: string;
  kind?: EntityActivitiesConnectionKind;
}

interface CalendarProps {
  user: UserActivityCalendarFragment$key;
  filters: ActivityFilter;
}

interface ActivityYearFilterProps {
  beginning: string;
  year?: number;
  setYear: (year: number) => void;
}

interface ActivityKindFilterProps {
  kind?: EntityActivitiesConnectionKind;
  setKind: (kind?: EntityActivitiesConnectionKind) => void;
}

export function UserActivity({
  user: userFragment,
  date,
  kind,
}: UserActivityProps) {
  const user = useFragment(UserActivityFragment, userFragment);

  const initialYear = useMemo(() => new Date(date).getFullYear(), [date]);
  const filters: ActivityFilter = useMemo(
    () => ({ kind, year: initialYear }),
    [kind, initialYear],
  );

  const setSearchFilters = useSetSearchActivityFilters();

  const handleFilterChange = ({ year, kind }: Partial<ActivityFilter>) => {
    setSearchFilters({
      year: year ? `${year}-01-01` : `${initialYear}-01-01`,
      ...addIfDefined("kind", kind),
    });
  };

  return (
    <div className="flex items-start gap-6 w-full">
      <div className="p-6 border border-grey rounded-md w-full bg-white">
        <Suspense
          fallback={
            <Skeleton theme={explicitTheme} colorScheme="light" loading />
          }
        >
          <Calendar user={user} filters={filters} />
        </Suspense>
        <ActivityKindFilter
          kind={filters.kind}
          setKind={(newKind) => handleFilterChange({ kind: newKind })}
        />
      </div>
      <ActivityYearFilter
        beginning={user.createdAt}
        year={filters.year}
        setYear={(newYear) => handleFilterChange({ year: newYear })}
      />
    </div>
  );
}

function Calendar({ user: userFragment, filters }: CalendarProps) {
  const intl = useIntl();
  const [user, refetch] = useRefetchableFragment(
    UserActivityCalendarFragment,
    userFragment,
  );

  const data = useMemo(() => {
    const year = filters.year || new Date().getFullYear();
    refetch({
      date: `${year}-01-01`,
      kinds: filters.kind ? [filters.kind] : undefined,
    });

    return generateDataArray(`${year}-01-01`, user.activities.edges);
  }, [filters, refetch, user.activities.edges]);

  if (!data.length) {
    return (
      <div className="container mx-auto h-full flex justify-center items-center px-4 py-4">
        <div className="w-full text-center">
          <p className="text-gray-500 italic">
            <FormattedMessage defaultMessage="No activity yet!" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <ActivityCalendar
        data={data}
        colorScheme="light"
        theme={explicitTheme}
        weekStart={1}
        maxLevel={4}
        showWeekdayLabels
        hideTotalCount
        renderBlock={(block, activity) =>
          cloneElement(block, {
            "data-tooltip-id": REACT_TOOLTIP_ID,
            "data-tooltip-html": intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, one {# point} other {# points}} on {date}",
              },
              {
                count: activity.count,
                date: intl.formatDate(activity.date, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }),
              },
            ),
          })
        }
      />
      <ReactTooltip id={REACT_TOOLTIP_ID} />
    </>
  );
}

function ActivityYearFilter({
  beginning,
  year: activeYear,
  setYear,
}: ActivityYearFilterProps) {
  return (
    <div className="flex flex-col gap-3">
      {generateYears(
        new Date(beginning).getFullYear(),
        new Date().getFullYear(),
      ).map((year) => (
        <Button
          key={year}
          kind={activeYear == year ? "primary" : "secondary"}
          onClick={() => {
            setYear(year);
          }}
        >
          {year}
        </Button>
      ))}
    </div>
  );
}

function ActivityKindFilter({
  kind: activeKind,
  setKind,
}: ActivityKindFilterProps) {
  const intl = useIntl();
  return (
    <div className="flex gap-3 mt-8">
      {ACTIVITY_KINDS.map((kind) => (
        <Button
          key={kind}
          kind={activeKind == kind ? "primary" : "secondary"}
          size="sm"
          onClick={() => setKind(kind)}
        >
          {formatActivityKind(intl, kind)}
        </Button>
      ))}
      <Button
        kind={activeKind == undefined ? "primary" : "secondary"}
        size="sm"
        onClick={() => setKind(undefined)}
      >
        {formatActivityKind(intl)}
      </Button>
    </div>
  );
}

function generateYears(start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i).reverse();
}

const generateDataArray = (
  year: string,
  activityEdges: UserActivityCalendarFragment$data["activities"]["edges"],
): Activity[] => {
  const startDate = new Date(year);
  const currentDate = new Date();
  const isCurrentYear = startDate.getFullYear() === currentDate.getFullYear();

  const endDate = isCurrentYear
    ? new Date(
        Math.min(
          currentDate.getTime() + GAP_BETWEEN_DATES * MILLISECONDS_IN_A_DAY,
          Date.now() + GAP_BETWEEN_DATES * MILLISECONDS_IN_A_DAY,
        ),
      )
    : new Date(startDate.getFullYear(), 11, 31);

  const activityMap = new Map(
    activityEdges.map(({ node }) => [
      node.date,
      { level: node.level, count: node.points, date: node.date },
    ]),
  );

  return Array.from(
    generateDateStrings(startDate, endDate),
    (date) => activityMap.get(date) || { level: 0, count: 0, date },
  );
};

function* generateDateStrings(start: Date, end: Date): Generator<string> {
  for (let d = new Date(start); d <= end; ) {
    yield d.toISOString().split("T")[0];
    d = new Date(d.getTime() + MILLISECONDS_IN_A_DAY);
  }
}
